import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';

const ValuationThankYou = (props) => {
    const [selectState, setselectState] = useState("sales");

    return (
        <div className="valuation-thanks-wrapper">
            <Row className="d-flex justify-content-center">
                <Col xl={10}>
                    <div className="text-center">
                        <p className="address"><i className="icon icon-location-bg"></i>{props.address}174 Upper Welland Road, Malvern WR14 4LA</p>
                        <div className="valuation-thanks-list">
                            <a href="javascript:;" onClick={() => setselectState("sales")}>Selling</a>
                            <a href="javascript:;" onClick={() => setselectState("lettings")}>Renting</a>
                        </div>
                    </div>

                    <div className="valuation-est-wrapper">
                        <div className="valuation-est-text-wrapper">
                            <div className="valuation-est-title">Estimated value</div>
                            <div className="valuation-est-price estimated">{selectState == "sales" ? props.average_sale_estimation.toLocaleString() : props.average_rent_estimation.toLocaleString()}</div>
                        </div>
                        <div className="divider-line"></div>
                        <div className="min-max-section">
                            <div className="valuation-est-text-wrapper">
                                <p className="valuation-est-title">Min Value</p>
                                <p className="valuation-est-price">£{selectState == "sales" ? props.minimum_sale_estimation.toLocaleString() : props.minimum_rent_estimation.toLocaleString()}</p>
                            </div>
                            <div className="valuation-est-text-wrapper mb-0">
                                <div className="valuation-est-title">Max Value</div>
                                <div className="valuation-est-price">£{selectState == "sales" ? props.maximum_sale_estimation.toLocaleString() : props.maximum_rent_estimation.toLocaleString()}</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ValuationThankYou