import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap";
import ValuationThankYou from "../ValuationThankYou/ValuationThankYou";
import NextStepModule from "../ValuationThankYou/NextStepModule";

import './assets/styles/_index.scss'
import BannerBreadcrumb from "../BreadcrumbModule/BannerBreadcrumb";
import GGFXImage from "../GGFX/GGFXImage";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const FormLayout = (props) => {
    const { children } = props
    var imagename = "page.banner_section_banner_image.landing_banner_image";

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.banner_section_banner_image_Transforms) {
        processedImages = props?.imagetransforms?.banner_section_banner_image_Transforms;
    }

    const [result, setResult] = useState(null);
    const [thankyou, setThanksyou] = useState(false);
    useEffect(() => {
        if (result === null && sessionStorage.getItem('valuationResult') != null) {
            setResult(JSON.parse(sessionStorage.getItem('valuationResult')))
        }
    })
    return (
        <div className="form-layout-wrapper">
            {props?.banner?.image &&
                <GGFXImage
                    ImageSrc={props?.banner?.image}
                    altText={props?.banner?.image?.alternativeText}
                    imagetransforms={props.ggfx_results}
                    renderer="srcSet"
                    imagename={imagename}
                    strapiID={props?.strapi_id}
                    className="img-fluid valuation-banner-img"
                />
            }
            <div className="overlay-bg"></div>
            <div className="layout-padding-top"></div>
            <Container>
                <Row className="d-flex justify-content-center">
                <BannerBreadcrumb {...props.choose_menu[0]} pageName={props.title} />

                    <Col xl={7}>
                        <div className={`form-layout-inner ${result ? `border_none` : ``}`}>
                            <div className="content-section" id="form-head">
                                <h1>{props?.officename ? `Contact ${props?.officename}` : props.banner.banner_title} {props?.name ? props.name : ""}</h1>
                                {props?.officename ? 
                                    <p>Contact us now for expert assistance with your queries and let us help you find the solutions you need.</p> 
                                :
                                    props?.banner?.banner_content &&
                                    <ContentModule Content={props?.banner?.banner_content?.data?.banner_content} />
                                }
                            </div>
                            {children}
                            {result && <ValuationThankYou {...result} />}
                        </div>
                        {result && <NextStepModule />}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FormLayout