import React from 'react'
import { Link } from 'gatsby'

const NextStepModule = () => {
    return (
        <div className='nextstep-module-wrapper'>
            <div className="nextstep-text-section">
                <h3>Ready to take the next step?</h3>
                <p>Book a home visit valuation with us today.</p>
            </div>
            <div>
                <Link to="/" className="button button-primary">Book an appointment</Link>
            </div>
        </div>
    )
}

export default NextStepModule