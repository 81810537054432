import React from "react"
import { Breadcrumb } from "react-bootstrap"
import { Link } from "gatsby";
import './assets/styles/_index.scss'

const BannerBreadcrumb = (props) => {
    return (
        <div className="banner-breadcrumb-wrapper">
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                {props.strapi_parent?.strapi_parent && <li className="breadcrumb-item">
                    <Link to={`/${props.strapi_parent?.strapi_parent?.slug}/`}>{props.strapi_parent?.strapi_parent?.title}</Link>
                </li>}
                {props.strapi_parent && <li className="breadcrumb-item">
                    <Link to={`${props?.strapi_parent?.strapi_parent?.slug ? `/${props?.strapi_parent?.strapi_parent?.slug}` : ""}/${props.strapi_parent?.slug}/`}>{props.strapi_parent?.title}</Link>
                </li>}
                <li className="breadcrumb-item active">{props.pageName}</li>
            </Breadcrumb>
        </div>
    )
}

export default BannerBreadcrumb