import React from "react"
import { graphql } from 'gatsby'
import Seo from "../components/seo"
import loadable from "@loadable/component";

import Layout from "../components/layout"
import ContactFormFields from "../forms_config/contact_form.json";
import CareerFormFields from "../forms_config/career_form.json";
import ValuationFormFields from "../forms_config/instant_valuation_form.json";
import HomeVisitValuationFormFields from "../forms_config/homevisit_valuation_form.json";
import BookAViewingSalesFormFields from "../forms_config/book_a_viewing_sales_form.json";
import BookAViewingLettingsFormFields from "../forms_config/book_a_viewing_lettings_form.json";
import TeamContactFormFields from "../forms_config/team_contact_form.json";
import DiscreetMarketingFormFields from "../forms_config/discreet_marketing_speak.json";
import PropertyEnquiryFormFields from "../forms_config/enquire_about_property.json";
import MortgageAdviceFormFields from "../forms_config/get_mortgage_advice.json";
import RegisterInterestFormFields from "../forms_config/register_your_interest.json";
import FormLayout from "../components/FormLayout/FormLayout";

const DefaultForm = loadable(() => import("../components/forms/default-form-layout"))

const FormTemplate = (props) => {
    const { data } = props
    const PageData = data?.strapiPage
    const to_email_id = props?.location?.state?.to_email_id
    const name = props?.location?.state?.name
    const email = props?.location?.state?.email
    const officename = props?.location?.state?.officename
    const prop_url = props.location?.state?.pageurl
    const prop_address = props.location?.state?.address
    const prop_img_url = props.location?.state?.propImage
    const type = props.location?.state?.type

    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <FormLayout {...PageData} name={name} email={email} officename={officename}>
                {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                    return (
                        <>
                            {module.strapi_component === "page-modules.global-module" && module.select_module === "contact_form" &&
                                <DefaultForm fields={ContactFormFields} classname="enquiry-form-wrapper" to_email_id={to_email_id} />
                            }
                            {module.strapi_component === "page-modules.global-module" && module.select_module === "instant_valuation_form" &&
                                <DefaultForm formfieldname="instant_valuation_form" fields={ValuationFormFields} classname="enquiry-form-wrapper" />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "team_contact_form" &&
                                <DefaultForm fields={TeamContactFormFields} classname="enquiry-form-wrapper" team_email={email} />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "book_a_viewing_form" &&
                                <DefaultForm
                                    fields={type === "sales" ? BookAViewingSalesFormFields : BookAViewingLettingsFormFields}
                                    classname="enquiry-form-wrapper"
                                    prop_url={prop_url}
                                    prop_address={prop_address}
                                    prop_img_url={prop_img_url}
                                    to_email_id={to_email_id}
                                />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "home_visit_valuation_form" &&
                                <DefaultForm fields={HomeVisitValuationFormFields} classname="enquiry-form-wrapper" />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "discreet_marketing_form" &&
                                <DefaultForm fields={DiscreetMarketingFormFields} classname="enquiry-form-wrapper" />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "property_enquire_form" &&
                                <DefaultForm fields={PropertyEnquiryFormFields} classname="enquiry-form-wrapper" />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "mortgage_advice_form" &&
                                <DefaultForm fields={MortgageAdviceFormFields} classname="enquiry-form-wrapper" />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "register_interest_form" &&
                                <DefaultForm fields={RegisterInterestFormFields} classname="enquiry-form-wrapper" />
                            }

                            {module.strapi_component === "page-modules.global-module" && module.select_module === "career_form" &&
                                <DefaultForm fields={CareerFormFields} classname="enquiry-form-wrapper" />
                            }
                        </>
                    )
                })}
            </FormLayout>
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default FormTemplate

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
            }
        }
    }
}`